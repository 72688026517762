.flynn-border {
    max-width: 70%;
    border-style: solid;
    border-width: thick;
    border-color: burlywood;
}

.flynn-wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
}

.flynn-title {
    font-size: xx-large;
}

.flynn-img {
    max-width: 100%;

}

.flynn-subtitle {
    font-size: small;
    color: #999;
}