
html, body, #root {
  height: 100%;
}

img {
  max-width: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.page {
  display: flex;
  flex-direction: column;
  background-color: #282c34;
  color: white;
  flex: 1;
}

footer {
  position: relative;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 10px 20px;
  overflow-x: hidden;
}

footer .content {
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
}

footer .content .media-icon {
  padding: 0 10px;
  color: white;
}

footer .copyright {
  color: #999;
  text-align: center;
}

.corner-cat {
  bottom: 0;
  right: 0;
  position: absolute;
  max-height: 100%;
  max-width: 30%;
}

@media (prefers-reduced-motion: no-preference) {
  .corner-cat {
    margin-right: -30%;
    animation: cat-appear infinite 20s linear;
  }
}

@media only screen and (min-width: 600px) and (prefers-reduced-motion: no-preference) {
  .corner-cat {
    max-width: 20%;
    margin-right: -20%;
    animation: cat-appear-600 infinite 20s linear;
  }
}

@media only screen and (min-width: 768px) and (prefers-reduced-motion: no-preference) {
  .corner-cat {
    max-width: 10%;
    margin-right: -10%;
    animation: cat-appear-768 infinite 20s linear;
  }
}

@keyframes cat-appear {
  0% {
    right: 0;
  }
  5% {
    right: 30%;
  }
  10% {
    right: 0;
  }
}
@keyframes cat-appear-600 {
  0% {
    right: 0;
  }
  5% {
    right: 20%;
  }
  10% {
    right: 0;
  }
}

@keyframes cat-appear-768 {
  0% {
    right: 0;
  }
  5% {
    right: 10%;
  }
  10% {
    right: 0;
  }
}