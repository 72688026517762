.grid-button {
    height: 100%;
    width: 100%;
}

.grid-col {
    height: 100px;
    padding: 15px 15px 15px 15px;
}

.grid-row {
    margin-bottom: 15px;
}

.hint {
    text-align: center;
    outline: 1px solid black;
}

.tiles {
    text-align: center;
    margin: 10% 10% 10% 10% ;
}

.tile {
    min-height: 10vh;
    text-align: center;
    border: 1px solid black;
    background: lightpink;
    cursor: pointer;
}

.tile.hidden {
    border: none;
    background: white;
    cursor: auto;
}