figure {
    margin: auto;
    width: 100%;
    padding-bottom: 10px;
    padding-top: 15px;
}

p {
    color: #FFFFFFC0;
}

figure img {
    margin: auto;
    width: 100%;
}

figure figcaption {
    margin: auto;
    width: 100%;
}

figure figcaption .credit {
    font-size: 8pt;
    text-align: right;
}

figure figcaption .description {
    padding-top: 5px;
    text-align: left;
}

.entry {
    padding-top: 5%;
    padding-bottom: 5%;
    justify-self: flex-start;
    text-align: left;
}

.bib-entry {
    text-indent: -26px;
    padding-left: 26px;
}