.App-logo {
    max-width: 50%;
    pointer-events: none;
}

.page {
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}